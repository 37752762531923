import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HyperCardMini from "../../components/hyper-card-mini"

const HyperKrank = () => {
  const data = useStaticQuery(graphql`
    query HyperKrankIndexQuery {
      krank: file(relativePath: { eq: "leichte-sprache/krank.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <HyperCardMini
      headingElement="h2"
      title="Hölderlin ist krank"
      image={data.krank}
      to="/leichte-sprache/ueber-hoelderlin/krank"
      cta="Mehr lesen"
    />
  )
}

export default HyperKrank
