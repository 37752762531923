import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HyperCardMini from "../../components/hyper-card-mini"

const HyperFamilie = () => {
  const data = useStaticQuery(graphql`
    query HyperFamilieIndexQuery {
      familie: file(relativePath: { eq: "leichte-sprache/familie.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <HyperCardMini
      headingElement="h2"
      attribution="DLA Marbach"
      title="Hölderlins Familie"
      image={data.familie}
      to="/leichte-sprache/ueber-hoelderlin/familie"
      cta="Mehr lesen"
    />
  )
}

export default HyperFamilie
