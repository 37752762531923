import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HyperCardMini from "../../components/hyper-card-mini"

const HyperFreunde = () => {
  const data = useStaticQuery(graphql`
    query HyperFreundeIndexQuery {
      freunde: file(relativePath: { eq: "leichte-sprache/freunde.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <HyperCardMini
      headingElement="h2"
      title="Hölderlins Freunde"
      image={data.freunde}
      to="/leichte-sprache/ueber-hoelderlin/freunde"
      cta="Mehr lesen"
    />
  )
}

export default HyperFreunde
