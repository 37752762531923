import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HyperCardMini from "../../components/hyper-card-mini"

const HyperTurm = () => {
  const data = useStaticQuery(graphql`
    query HyperTurmIndexQuery {
      turmzimmer: file(relativePath: { eq: "leichte-sprache/tisch.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <HyperCardMini
      headingElement="h2"
      title="Hölderlins Zeit im Tübinger Turm"
      image={data.turmzimmer}
      attribution="David Franck"
      to="/leichte-sprache/ueber-hoelderlin/turm"
      cta="Mehr lesen"
    />
  )
}

export default HyperTurm
