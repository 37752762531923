import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Image from "../../components/image"
import ImageHotspot from "../../components/image-hotspot"
import ImageHotspotsModalTemplate from "../../components/image-hotspots-modal-template"
import ImageHotspots from "../../components/image-hotspots"
import Stack from "../../components/stack"

import ReadMoreButton from "../../components/read-more-button"
import Box from "../../components/box"

const AussichtLeichteSprache = () => {
  const data = useStaticQuery(graphql`
    query AussichtLeichteSpracheIndexQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht.png"
        }
      ) {
        ...largeImage
      }
      aussicht01: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-01.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht02: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-02.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht03: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-03.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht04: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-04.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht05: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-05.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht06: file(
        relativePath: {
          eq: "dauerausstellung/06-hoelderlins-turmzimmer/aussicht-06.jpg"
        }
      ) {
        ...largeImage
      }
      aussicht07: file(
        relativePath: { eq: "leichte-sprache/aussicht-07.jpg" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Stack>
      <Box bg="muted" p={6}>
        <Paragraph>
          Sie können auf die <ReadMoreButton as="span" />
          -Zeichen im Bild klicken. <br />
          Dann erfahren Sie mehr über das Manuskript.
        </Paragraph>
      </Box>
      <ImageHotspots
        image={
          <Image
            image={data.gedicht.childImageSharp.gatsbyImageData}
            attribution="Deutsches Literaturarchiv Marbach"
            alt="Gedichtmanuskript ›Aussicht‹"
          />
        }
      >
        <ImageHotspot x={35} y={8} name="aussicht-01" label="Die Über∙schrift">
          <ImageHotspotsModalTemplate
            title="Die Über∙schrift"
            image={
              <Image
                image={data.aussicht01.childImageSharp.gatsbyImageData}
                alt="Vergrößerte Darstellung des Gedichttitels"
              />
            }
          >
            <Paragraph>
              Hölderlin schreibt ein Gedicht. <br />
              Das Gedicht heißt: <br />
              ›Die Aussicht‹. <br />
              Hölderlin schreibt zuerst die Über∙schrift. <br />
              Dann macht ein einen Punkt. <br />
              Er denkt nach. <br />
              Er schaut aus dem Fenster. <br />
              Erst dann schreibt er die erste Zeile.
            </Paragraph>
          </ImageHotspotsModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={27} y={20} name="aussicht-03" label="Tinten∙spritzer">
          <ImageHotspotsModalTemplate
            title="Tinten∙spritzer"
            image={
              <Image
                image={data.aussicht03.childImageSharp.gatsbyImageData}
                alt="Tintenkleckse auf dem Manuskript"
              />
            }
          >
            <Paragraph>
              Zu Hölderlins Zeit gibt es noch keine Computer. <br />
              Man schreibt mit Tinte. <br />
              Und mit einer spitzen Feder. <br />
              Auch Hölderlin schreibt seine Gedichte <br />
              mit einer Feder. <br />
              Er taucht sie in ein Tinten∙fass. <br />
              Manchmal spritzt die Feder beim Schreiben. <br />
              Dann entstehen schöne Muster auf dem Papier.
            </Paragraph>
          </ImageHotspotsModalTemplate>
        </ImageHotspot>
        <ImageHotspot
          x={17}
          y={85}
          name="aussicht-04"
          label="Falsche Jahres∙zahlen"
        >
          <ImageHotspotsModalTemplate
            title="Falsche Jahres∙zahlen"
            image={
              <Image
                image={data.aussicht04.childImageSharp.gatsbyImageData}
                alt="Die vergrößerte Darstellung der linken Ecke zeigt Hölderlins Datierung auf den 24. März 1871"
              />
            }
          >
            <Paragraph>
              Hölderlin schreibt unter das Gedicht ein Datum: <br />
              den 24. März 1871. <br />
              Aber wir wissen: <br />
              Hölderlin ist schon 1843 gestorben. <br />
              Das Gedicht kann nicht 1871 entstanden sein. <br />
              Hölderlin hat dieses Datum erfunden.
            </Paragraph>
          </ImageHotspotsModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={20} y={90} name="aussicht-05" label="Korrektur">
          <ImageHotspotsModalTemplate
            title="Korrektur"
            image={
              <Image
                image={data.aussicht05.childImageSharp.gatsbyImageData}
                alt="Handschriftliche Korrektur mit Bleistift"
              />
            }
          >
            <Paragraph>
              Mit einem Blei∙stift hat jemand anderes <br />
              noch etwas dazu∙geschrieben.
              <br />
              Er hat Hölderlins Datum korrigiert. <br />
              Er notiert: <br />
              Das Gedicht wurde <br />
              »am 12. April 1842 <br />
              von Hölderlin, dem Unglüklichen, <br />
              geschrieben.«
              <br />
              Zu dieser Zeit war Hölderlin <br />
              schon 72 Jahre alt.
            </Paragraph>
          </ImageHotspotsModalTemplate>
        </ImageHotspot>
        <ImageHotspot
          x={57}
          y={35}
          name="aussicht-06"
          label="Klang oder Glanz?"
        >
          <ImageHotspotsModalTemplate
            title="Klang oder Glanz?"
            image={
              <Image
                image={data.aussicht06.childImageSharp.gatsbyImageData}
                alt="Detailansicht der Handschrift"
              />
            }
          >
            <Paragraph>
              Hölderlins Hand∙schrift ist schwer zu lesen.
              <br />
              Auch für die Expertinnen und Experten.
              <br />
              Manchmal machen sie dabei auch Fehler.
              <br />
              Lange dachten sie:
              <br />
              Hölderlin schreibt hier vom
              <br />
              »Klang des Tages«.
              <br />
              Heute weiß man:
              <br />
              Das K ist ein G.
              <br />
              Und das g ist ein z.
              <br />
              Hölderlin hat über den
              <br />
              »Glanz des Tages«
              <br />
              geschrieben.
            </Paragraph>
          </ImageHotspotsModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={65} y={80} name="aussicht-07" label="Scardanelli">
          <ImageHotspotsModalTemplate
            title="Scardanelli"
            image={
              <Image
                image={data.aussicht07.childImageSharp.gatsbyImageData}
                alt="Detailansicht der Handschrift"
              />
            }
          >
            <Paragraph>
              Im Turm gibt sich Hölderlin einen neuen Namen.
              <br />
              Er will nicht mehr Hölderlin heißen.
              <br />
              Er will »Scardanelli« genannt werden.
              <br />
              Mit diesem Namen unter∙schreibt er auch sein Gedicht.
            </Paragraph>
          </ImageHotspotsModalTemplate>
        </ImageHotspot>
      </ImageHotspots>
    </Stack>
  )
}

export default AussichtLeichteSprache
