import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HyperCardMini from "../../components/hyper-card-mini"

const HyperOrte = () => {
  const data = useStaticQuery(graphql`
    query HyperOrteIndexQuery {
      orte: file(relativePath: { eq: "leichte-sprache/orte.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <HyperCardMini
      headingElement="h2"
      title="Diese Orte waren wichtig in Hölderlins Leben"
      image={data.orte}
      to="/leichte-sprache/ueber-hoelderlin/orte"
      attribution="David Franck"
      cta="Mehr lesen"
    />
  )
}

export default HyperOrte
