import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HyperCardMini from "../../components/hyper-card-mini"

const HyperAussicht = () => {
  const data = useStaticQuery(graphql`
    query HyperAussichtIndexQuery {
      aussicht: file(
        relativePath: { eq: "leichte-sprache/aussicht-zimmer.jpg" }
      ) {
        ...smallImage
      }
    }
  `)

  return (
    <HyperCardMini
      headingElement="h2"
      title="Hölderlins Gedicht ›Aussicht‹"
      image={data.aussicht}
      to="/leichte-sprache/ueber-hoelderlin/aussicht"
      attribution="David Franck"
      cta="Mehr lesen"
    />
  )
}

export default HyperAussicht
