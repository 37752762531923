import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HyperCardMini from "../../components/hyper-card-mini"

const HyperVerliebt = () => {
  const data = useStaticQuery(graphql`
    query HyperVerliebtIndexQuery {
      verliebt: file(relativePath: { eq: "leichte-sprache/verliebt.jpg" }) {
        ...smallImage
      }
    }
  `)

  return (
    <HyperCardMini
      headingElement="h2"
      title="Hölderlin verliebt sich"
      image={data.verliebt}
      attribution="Barbara Klemm"
      to="/leichte-sprache/ueber-hoelderlin/verliebt"
      cta="Mehr lesen"
    />
  )
}

export default HyperVerliebt
